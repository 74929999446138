import '../styles/views/Blogs.css';
import Banner from '../components/Banner';

const Blogs = () => {

    return (
        <div className="blogs">
            <Banner title={"Blogs"}/>
        </div> 
    );
}

export default Blogs